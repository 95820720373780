<template>
  <div>
    <div class="d-flex" v-if="canEdit">
      <b-form-group v-if="showStartDate" :label="`${$filters.convertFromCamelCase(objectLevel)} Start Date`">
        <div class="d-flex-center">
          <single-date-picker :value="campaignPlan.startDate"
                              :disabled="disableStartDate"
                              opens="right"
                              @input="campaignPlan.startDate = $event; saveStartDate()"></single-date-picker>
          <locked-field-v3 :value="campaignPlan.lockedFields"
                           class="ml-3"
                           field="startDate"
                           @input="campaignPlan.lockedFields = $event; emitInput()"/>
          <info-tooltip v-if="disableStartDate" title="The start date cannot be changed if the campaign is already active."/>
        </div>
        <template #description>
          <span v-if="disableStartDate">The Start Date cannot be edited once the date is in the past and has been pushed to the advertising partner.</span>
        </template>
      </b-form-group>
      <b-form-group v-if="showEndDate" class="justify-content-between" :class="{'ml-5': showStartDate}" :label="`${$filters.convertFromCamelCase(objectLevel)} End Date`">
        <div class="d-flex-center">
          <single-date-picker :value="campaignPlan.endDate"
                              :min-date="minimumEndDate"
                              @input="campaignPlan.endDate = $event; saveEndDate()" />
          <locked-field-v3 :value="campaignPlan.lockedFields"
                           class="ml-3"
                           field="endDate"
                           @input="campaignPlan.lockedFields = $event; emitInput()"/>
        </div>
        <div class="mt-4">
          <t-button-inline v-if="isFacebook && objectLevel === 'campaign'"
                 @click="sendEndDate">
            <fluency-icon v-if="sendingEndDate" class="rotating" type="loop" />
            <fluency-icon v-if="sentEndDate && !sendingEndDate" type="checkmark" />
            Push End Date to Ad Sets
          </t-button-inline>
        </div>
      </b-form-group>
    </div>
    <div v-else>
      Cannot edit start and end dates for search at the ad group level.
    </div>
    <t-alert v-if="isLifetimeBudget"
             :show="inheritedBudgetDates"
              style="flex-basis: calc(75% - 10px)">
      These dates are inherited from the <nuxt-link :to="`/manage/budget/?id=${budgetPlan.budgetPlanId}`">Budget</nuxt-link>. Enter dates here to override the budget dates</t-alert>
    <t-alert v-if="hasAdGroupsWithStartAndEndDates && objectLevel === 'campaign'"
            show>
      The start and end dates you have set at the Ad Set level under this Campaign will override this Campaign level start and end date, so changing this date will not affect your Ad Set level start and end dates.

      <b-button variant="link" class="px-0"> Click here </b-button> to set all Ad Set level start and end dates to the Campaign level start and end dates you see here.
    </t-alert>
  </div>
</template>

<script>
import LockedFieldV3 from '@/components/common/lockedFieldV3'
import InfoTooltip from '@/components/common/forms/infoTooltip.vue'
import SingleDatePicker from '@/components/common/dateSelector/components/singleDatePicker.vue'

export default {
  name: 'CampaignStartAndEndDates',
  components: { SingleDatePicker, InfoTooltip, LockedFieldV3 },
  props: {
    value: { // campaignPlanDTO
      type: Object,
      required: true
    },
    showStartDate: {
      type: Boolean,
      default: true
    },
    showEndDate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      sendingEndDate: false,
      sentEndDate: false,
      campaignPlan: {},
      preventLoop: false
    }
  },
  inject: {
    validate: {
      from: 'validate',
      default: () => {
        return (name, { isValid }) => isValid
      }
    }
  },
  watch: {
    value: {
      handler () {
        if (!this.preventLoop) {
          this.campaignPlan = { ...this.value }
        }
      },
      immediate: true
    }
  },
  computed: {
    canEdit () {
      return (!this.hasGoogle && this.objectLevel === 'adGroup') || (this.objectLevel === 'campaign')
    },
    hasGoogle () {
      return this.campaignPlan.advertisingChannelId === 1
    },
    objectLevel () {
      if (this.value.adGroupPlanId) {
        return 'adGroup'
      } else {
        return 'campaign'
      }
    },
    disableStartDate () {
      // we need to disable the start date if there was already one set in the past and its
      // active because of the partner logic preventing setting it - SNG trello HcDV4PAL
      const hasReferenceId = (this.campaignPlan.partnerObjects?.length > 0) ? this.campaignPlan.partnerObjects[0].referenceId : false
      const startDateInThePast = (new Date(this.campaignPlan.startDate) - new Date()) < 0
      return (hasReferenceId && startDateInThePast)
    },
    budgetPlan () {
      return this.campaignPlan.budgetPlan
    },
    adGroupPlans () {
      const adGroupPlans = Array.from(this.$store.getters.activeChildItems('adGroup')?.values() ?? [])
      return adGroupPlans.filter(agp => agp.campaignPlanId === this.campaignPlan.campaignPlanId)
    },
    isLifetimeBudget () {
      return this.budgetPlan?.budgetType === 'LIFETIME'
    },
    hasAdGroupsWithStartAndEndDates () {
      if (this.adGroupPlans.length === 0) {
        return false
      }
      return this.adGroupPlans.some(agp => agp.startDate || agp.endDate)
    },
    inheritedBudgetDates () {
      return !this.campaignPlan.startDate && !this.campaignPlan.endDate &&
        (this.budgetPlan.startDate || this.budgetPlan.endDate)
    },
    isFacebook () {
      return this.campaignPlan.advertisingChannelId === 2
    },
    minimumEndDate () {
      const start = this.$moment(new Date(this.campaignPlan.startDate))
      const today = this.$moment()
      const max = this.$moment.max(start, today)
      return max.toDate()
    }
  },
  methods: {
    async sendEndDate () {
      this.$setCompat(this, 'sendingEndDate', true)

      const response = await this.$res.set.adgroupPlanEndDateForCampaignPlan(this.campaignPlan)

      if (response) {
        this.$setCompat(this, 'sentEndDate', true)
        this.$toast('End Date successfully sent to Ad Sets', 'success')
      }

      this.$setCompat(this, 'sendingEndDate', false)
    },
    fetchAdGroups () {
      if (!this.$store.getters.activeChildItems('adGroup')) {
        this.$store.dispatch('fetchPlansWithMetrics', { fetchType: 'adGroup' })
      }
    },
    saveStartDate () {
      this.campaignPlan.lockedFields = LockedFieldV3.addLocks(this.campaignPlan, 'startDate')
      this.emitInput()
    },
    saveEndDate () {
      this.campaignPlan.lockedFields = LockedFieldV3.addLocks(this.campaignPlan, 'endDate')
      this.emitInput()
    },
    emitInput () {
      this.campaignPlan.dirty = true
      this.preventLoop = true
      this.$emit('input', this.campaignPlan)
    }
  },
  mounted () {
    this.fetchAdGroups()
  }
}
</script>

<style scoped>

</style>
